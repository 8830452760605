.logo {
  height: 60px; /* Ajusta la altura al 100% del contenedor */
  width: auto; /* Permite que el ancho se ajuste proporcionalmente */
}

.header-title {
  font-size: 48px; /* Adjust the font size according to your preference */
  font-weight: bold; /* Add additional styles if desired */
}

.images {
  align-items: center;
  width: 40%;

}

/* Estilos para el encabezado */
header {
  background-color: #1A2C4C;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  
}

header nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

header nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Estilos para los enlaces */
a {
  color: #1A2C4C;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Estilos para el botón */
button {
  background-color: #1A2C4C;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}

button:hover {
  background-color: #fff;
  color: #1A2C4C;
}

/* Estilos para los encabezados */
h1, h2, h3 {
  color: #1A2C4C;
  text-align: center;
}

/* Estilos para el cuerpo principal */
main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Estilos para la sección de productos */
#productos ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

#productos li {
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 400px;
  text-align: center;
  padding: 1rem;

}

#productos li h3 {
  margin-top: 0;
}

#productos li img {
  width: 80%;
  height: auto;
  margin: 1rem auto;
}

/* Estilos para la sección de contacto */
#contacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#contacto ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#contacto form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  text-align: left;
}

#contacto form label {
  display: block;
  font-weight: bold;
}

#contacto form input,
#contacto form textarea {
  padding: 0.5rem;
  border: 1px solid #1A2C4C;
  border-radius: 5px;
}

#contacto form button {
  background-color: #1A2C4C;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}

#contacto form button:hover {
  background-color: #fff;
  color: #1A2C4C;
}


